import React, { Component } from 'react'

class DropDown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedStallion: '',
            currentTab: '' 
        };
    }
    
    handleChange = (event) => {
        const selectedStallion = event.target.value;
        
        this.setState({ 
            selectedStallion: selectedStallion,
            currentTab: selectedStallion
        });
        console.log('Selected stallion:', selectedStallion);
        console.log('event.target:', event.target);
        this.props.setCurrentTab(selectedStallion)
    };

    handleButtonClick = () => {
        if (this.state.currentTab) {
            this.props.history.push(`/horses/detail?id=${this.state.currentTab}`);
        }
      };

    render() {
        const { stallions } = this.props;
        if (!stallions || stallions.length === 0) {
            return <p className='stallion-page_message'>No stallions available</p>;
        }
        const isButtonEnabled = !!this.state.currentTab;

        return (
            <div className="dropdown_container">
                <label className="dropdown_label">Select a Stallion</label>
                <select
                    className="dropdown_select"
                    value={this.state.selectedStallion}
                    onChange={this.handleChange}
                >
                    <option value="">--Select--</option>
                    
                    {stallions.map((stallion) => (
                        <option key={stallion.id} value={stallion.id}>
                            {stallion.name}
                        </option>
                    ))}
                </select>

            <button onClick={this.handleButtonClick} disabled={!isButtonEnabled} 
            className='stallion-page_button' > Stallion details</button>
                
            </div>
        );
    }
}

export default DropDown;
