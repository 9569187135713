import { RSAA } from 'redux-api-middleware'


export const pageStallionBreedingConsts = {
  REQUEST: '@@PAGE_STALLION_BREEDING/PAGE_STALLION_BREEDING_REQUEST',
  SUCCESS: '@@PAGE_STALLION_BREEDING/PAGE_STALLION_BREEDING_SUCCESS',
  FAILURE: '@@PAGE_STALLION_BREEDING/PAGE_STALLION_BREEDING_FAILURE',
}


export const fetchStallionBreeding = () => ({
  [RSAA]: {
    endpoint: '/api/v2/service/stallion',
    method: 'GET',
    headers: { 
      'Content-type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('authorization_token')}` 
    },
    types: [
      pageStallionBreedingConsts.REQUEST,
      {
        type: pageStallionBreedingConsts.SUCCESS,
        payload: async (action, state, res) => {
          const payload = await res.json()
          let tableData = {}
          tableData.tableHeadings = ['Horse', 'Owned', 'Sire', 'Birth', 'Generation', 'Gender', '$CAD', '$USD']
          tableData.tableEntities = []
          payload.items.forEach((item) => {
            let entity = {
              id: item.id,
              name: item.name,
            }
            tableData.tableEntities.push(entity)
          })
          return tableData
        }
      },
      pageStallionBreedingConsts.FAILURE
    ]
  }
})