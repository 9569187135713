import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'

import { pageHome } from './page-home.reducer';
import { pageBreeding } from './page-breeding.reducer';
import { pageStallion } from './page-stallion.reducer'
import { pageLocation } from './page-location.reducer';
import { pageHorses } from './page-horses.reducer';
import { pageDetail } from './page-detail.reducer';
import { pageResults } from './page-results.reducer';
import { pageLicense } from './page-license.reducer';
import { pageEvents } from './page-events.reducer';
import { pageNewsfeed } from './page-newsfeed.reducer';
import { pageNewsArticle } from './page-newsarticle.reducer';
import { pageLogin } from './page-login.reducer';


const rootReducer = (history) => (combineReducers({
  router: connectRouter(history),
  pageHomeData: pageHome,
  pageBreedingData: pageBreeding,
  pageStallionData: pageStallion,
  pageLocationData: pageLocation,
  pageHorsesData: pageHorses,
  pageDetailData: pageDetail,
  pageResultsData: pageResults,
  pageLicenseData: pageLicense,
  pageEventsData: pageEvents,
  pageNewsfeedData: pageNewsfeed,
  pageNewsArticleData: pageNewsArticle,
  pageLoginData: pageLogin
}))

export default rootReducer;