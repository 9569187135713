import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import Header from './_components/Header'
import Footer from './_components/Footer'
import PageContainer from './_components/PageContainer'

import PrivateRoute from './_components/PrivateRoute'
import LogInPage from './_containers/LogInPage'
import HomePage from './_containers/HomePage'
import BreedingPage from './_containers/BreedingPage'
import HorseLocationPage from './_containers/HorseLocationPage'
import HorsesPage from './_containers/HorsesPage'
import HorseDetailPage from './_containers/HorseDetailPage'
import RacingResultsPage from './_containers/RacingResultsPage'
import RacingLicensePage from './_containers/RacingLicensePage'
import UpcomingEventsPage from './_containers/UpcomingEventsPage'
import NewsfeedPage from './_containers/NewsfeedPage'
import NewsArticlePage from './_containers/NewsArticlePage'
import StallionsPage from './_containers/StallionsPage'

import './_styles/imports.scss'

class App extends Component {

  render() {
    return (
      <Route render={({ location, history }) => (
        <React.Fragment>

          <Route
            exact
            path="/"
            render={() => <Redirect to="/login" />}
          />

          {location.pathname !== '/login' &&
            <Header history={history} location={location} />
          }

          <PageContainer location={location}>
            <Switch location={location}>
              <Route path="/login" component={LogInPage} />
              <PrivateRoute exact path="/home" component={HomePage} />
              <PrivateRoute path="/breeding" component={BreedingPage} />
              <PrivateRoute path="/stallions" component={StallionsPage} />
              <PrivateRoute path="/horse-location" component={HorseLocationPage} />
              <PrivateRoute path="/horses/detail/:id" render={(props) => (
                <HorseDetailPage key={props.match.params.id} {...props} />)
              } />
              <PrivateRoute path="/horses/detail" component={HorseDetailPage} />
              <PrivateRoute path="/horses" component={HorsesPage} />
              <PrivateRoute path="/racing-results" component={RacingResultsPage} />
              <PrivateRoute path="/racing-license" component={RacingLicensePage} />
              <PrivateRoute path="/upcoming-events" component={UpcomingEventsPage} />
              <PrivateRoute path="/newsfeed/article" component={NewsArticlePage} />
              <PrivateRoute path="/newsfeed" component={NewsfeedPage} />
            </Switch>
          </PageContainer>

          {location.pathname !== '/login' &&
            <Footer />
          }

        </React.Fragment>
      )} />
    );
  }
}

export default App