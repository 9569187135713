import {
  pageStallionBreedingConsts
} from '../_actions/page-stallion.actions';

const initialState = {
  births: { needsInit: true }
}

export function pageStallion(state = initialState, action) {
  switch (action.type) {
    case pageStallionBreedingConsts.REQUEST:
      return {
        ...state,
        births: { loading: true }
      }
    case pageStallionBreedingConsts.SUCCESS:
      return {
        ...state,
        births: action.payload
      }
    case pageStallionBreedingConsts.FAILURE:
      return {
        ...state,
        births: { error: action.payload.status }
      }
    case 'USER_LOGOUT':
      return initialState
    case 'USER_LOGOUT_NO_MSG':
      return initialState
    default:
      return state
  }
}
