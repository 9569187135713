import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet'

import { fetchStallionBreeding } from '../../_actions/page-stallion.actions'
import { userLogout } from '../../_actions/logout.actions'

import PageHeader from '../../_components/PageHeader'
import StallionsTable from '../../_components/StallionsTable'
import LoadingSplash from '../../_components/LoadingSplash'

class StallionsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: true
    }
  }

  async componentDidMount() {
    window.scrollTo(0, 0)

    if (
      this.props.pageStallionData.births.needsInit ||
      this.props.pageStallionData.births.error

    ) {
      await Promise.all([this.props.dispatch(fetchStallionBreeding())])
    }

    if (
      this.props.pageStallionData.births.error
    ) {
      if (
        this.props.pageStallionData.births.error === 401  
      ) {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    } else {
      this.setState({
        isLoading: false
      })
      
      const res = await fetch('/auth-jwt-refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
      })
      const resJson = await res.json();

      if (res.status === 200) {
        localStorage.setItem('authorization_token', resJson.token)
      } else {
        this.props.dispatch(userLogout())
        this.props.history.push(`/login?referrer=${window.location.pathname}`)
      }
    }

  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Chiefswood Stables | Stallions</title>
        </Helmet>
        {this.state.isLoading ? <LoadingSplash /> :
          <React.Fragment>
            <PageHeader heading={'Stallions'} />
            <StallionsTable 
              tableData={this.props.pageStallionData.births}
              history={this.props.history}
             />
          </React.Fragment>}
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const { pageStallionData } = state
  return {
    pageStallionData
  }
}

export default connect(mapStateToProps)(StallionsPage)
