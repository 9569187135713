import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Default, Mobile } from '../_mediaqueries'
import { CSSTransition } from 'react-transition-group'
import { connect } from 'react-redux';

import smallLogo from '../../_img/chiefswood-small-logo.png'
import { userLogout } from '../../_actions/logout.actions'

import { iconHome, IconBreeding, iconHorseLocation, iconHorses, iconRacingResults, iconRacingLicense, iconUpcomingEvents, iconNewsfeed } from './iconSvgs'
import '../../_styles/_vendor/hamburgers/hamburgers.scss'

class Header extends Component {
  constructor(props) {
    super(props)

    this.state = {
      mobileNavOpen: false,
      userMenuOpen: false
    }

    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.handleDocClick = this.handleDocClick.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      if (this.state.mobileNavOpen) {
        this.setState({
          mobileNavOpen: false
        })
      }
    });
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocClick);
  }

  componentWillUnmount() {
    this.unlisten();
    document.removeEventListener('click', this.handleDocClick);
  }

  handleDocClick(e) {
    if (!e.target.classList.contains('nav_user-name') && this.state.userMenuOpen) {
      this.setState({ userMenuOpen: false })
    }
  }

  toggleMobileNav() {
    this.setState({
      mobileNavOpen: !this.state.mobileNavOpen
    })
  }

  toggleUserMenu(e) {
    e.stopPropagation();
    this.setState({
      userMenuOpen: !this.state.userMenuOpen
    })
  }

  async logout() {
    const res = await fetch('/rest-auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: localStorage.getItem('authorization_token') })
    })

    this.props.dispatch(userLogout('NO_MSG'))
    this.props.history.push('/login');
  }

  render() {
    const location = this.props.location.pathname.slice(1)
    return (
      <nav className="nav">
        <div className="container-fluid nav_top-bar">
          <div className="row no-gutters justify-content-between">
            <div className="col-auto">
              <Link to="/login" label="Login">
                <img src={smallLogo} />
              </Link>
            </div>
            <Default>
              <div className="col-auto">
                <div className="nav_userinfo-wrap d-inline-flex align-items-center h-100">
                  <div onClick={this.toggleUserMenu} className="nav_user-name">{localStorage.getItem('current_username')}</div>
                  {this.state.userMenuOpen &&
                    <div className="nav_user-menu">
                      <div onClick={this.logout} className="nav_user-option">Logout</div>
                    </div>
                  }
                </div>
              </div>
            </Default>
            <Mobile>
              <button onClick={this.toggleMobileNav.bind(this)}
                className={`hamburger hamburger--squeeze${this.state.mobileNavOpen ? ' is-active' : ''}`} type="button">
                <span className="hamburger-box">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
            </Mobile>
          </div>
        </div>
        <Default>
          <div className="container-fluid nav_bot-bar">
            <div className="row no-gutters">
              <div className="col d-flex flex-wrap flex-md-nowrap justify-content-around align-items-center">
                <Link to="/home" label="Home" className={`nav_page-link${location === 'home' ? ' is-active' : ''}`}>
                  {iconHome}
                  <div>Home</div>
                </Link>
                <Link to="/breeding" label="Breeding" className={`nav_page-link${location === 'breeding' ? ' is-active' : ''}`}>
                  <IconBreeding />
                  <div>Breeding</div>
                </Link>
                <Link to="/stallions" label="Stallions" className={`nav_page-link${location === 'stallions' ? ' is-active' : ''}`}>
                  <IconBreeding />
                  <div>Stallions</div>
                </Link>
                <Link to="/horse-location" label="Horse Location" className={`nav_page-link${location === 'horse-location' ? ' is-active' : ''}`}>
                  {iconHorseLocation}
                  <div>Horse Location</div>
                </Link>
                <Link to="/horses" label="Horses" className={`nav_page-link${location === 'horses'
                  || location.split('/').splice(0, 2).join('/') === 'horses/detail' ? ' is-active' : ''}`}>
                  {iconHorses}
                  <div>Horses</div>
                </Link>
                <Link to="/racing-results" label="Racing Results" className={`nav_page-link${location === 'racing-results' ? ' is-active' : ''}`}>
                  {iconRacingResults}
                  <div>Racing Results</div>
                </Link>
                <Link to="/racing-license" label="Racing License" className={`nav_page-link${location === 'racing-license' ? ' is-active' : ''}`}>
                  {iconRacingLicense}
                  <div>Racing License</div>
                </Link>
                <Link to="/upcoming-events" label="Upcoming Events" className={`nav_page-link${location === 'upcoming-events' ? ' is-active' : ''}`}>
                  {iconUpcomingEvents}
                  <div>Upcoming Events</div>
                </Link>
                <Link to="/newsfeed" label="Newsfeed" className={`nav_page-link${location === 'newsfeed'
                  || location.split('/').splice(0, 2).join('/') === 'newsfeed/article' ? ' is-active' : ''}`}>
                  {iconNewsfeed}
                  <div>Newsfeed</div>
                </Link>
              </div>
            </div>
          </div>
        </Default>
        <Mobile>
          <CSSTransition
            in={this.state.mobileNavOpen}
            timeout={200}
            classNames='nav_bot-bar'
          >
            <div className="container-fluid nav_bot-bar">
              <div className="row no-gutters">
                <div className="col d-flex h-100 flex-wrap">
                  <Link to="/home" label="Home" className={`nav_page-link${location === 'home' ? ' is-active' : ''}`}>
                    {iconHome}
                    <div>Home</div>
                  </Link>
                  <Link to="/breeding" label="Breeding" className={`nav_page-link${location === 'breeding' ? ' is-active' : ''}`}>
                    <IconBreeding />
                    <div>Breeding</div>
                  </Link>
                  <Link to="/horse-location" label="Horse Location" className={`nav_page-link${location === 'horse-location' ? ' is-active' : ''}`}>
                    {iconHorseLocation}
                    <div>Horse Location</div>
                  </Link>
                  <Link to="/horses" label="Horses" className={`nav_page-link${location === 'horses'
                    || location.split('/').splice(0, 2).join('/') === 'horses/detail' ? ' is-active' : ''}`}>
                    {iconHorses}
                    <div>Horses</div>
                  </Link>
                  <Link to="/racing-results" label="Racing Results" className={`nav_page-link${location === 'racing-results' ? ' is-active' : ''}`}>
                    {iconRacingResults}
                    <div>Racing Results</div>
                  </Link>
                  <Link to="/racing-license" label="Racing License" className={`nav_page-link${location === 'racing-license' ? ' is-active' : ''}`}>
                    {iconRacingLicense}
                    <div>Racing License</div>
                  </Link>
                  <Link to="/upcoming-events" label="Upcoming Events" className={`nav_page-link${location === 'upcoming-events' ? ' is-active' : ''}`}>
                    {iconUpcomingEvents}
                    <div>Upcoming Events</div>
                  </Link>
                  <Link to="/newsfeed" label="Newsfeed" className={`nav_page-link${location === 'newsfeed'
                    || location.split('/').splice(0, 2).join('/') === 'newsfeed/article' ? ' is-active' : ''}`}>
                    {iconNewsfeed}
                    <div>Newsfeed</div>
                  </Link>
                </div>
              </div>
            </div>
          </CSSTransition>
        </Mobile>
      </nav>
    )
  }
}

export default connect()(Header);