import React, { Component } from 'react';
import { connect } from 'react-redux';
import DropDown from './DropDown';
import { fetchHorseDetail } from '../../_actions/page-detail.actions';
import { handleViewNote } from '../_utils';

import iconSortArrow from '../../_img/icon-sort-arrow.svg';
import iconDownArrow from '../../_img/icon-arrow-down.svg';

class StallionsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTableEntities: props.tableData.tableEntities || [], // Initialize with tableEntities from props
      currentTab: null,
      sortColumn: null, // Track the column being sorted
      sortDirection: 'asc', // Track sorting direction (asc or desc)
    };

    this.MASTER_TABLE_ENTITIES = [];
    this.isMobile = null;
    this.checkIfMobile = this.checkIfMobile.bind(this);
  }

  componentDidMount() {
    this.MASTER_TABLE_ENTITIES = [...this.props.tableData.tableEntities];
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    window.addEventListener('resize', this.checkIfMobile);
    this.props.fetchHorseDetail();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkIfMobile);
  }

  componentDidUpdate(prevProps) {
    // Check if the pageDetailData has changed
    if (prevProps.pageDetailData !== this.props.pageDetailData) {
      const newTableEntities = 
        (this.props.pageDetailData && 
         this.props.pageDetailData.details && 
         this.props.pageDetailData.details.sireDescendants && 
         this.props.pageDetailData.details.sireDescendants.tableEntities) || [];
  
         if (JSON.stringify(newTableEntities) !== JSON.stringify(this.state.currentTableEntities)) {
          this.setState({ currentTableEntities: newTableEntities });
         }
    }
  }
  
  checkIfMobile() {
    this.isMobile = window.matchMedia('(max-width: 767px)').matches;
    this.forceUpdate();
  }

  sortTableEntities = (columnIndex) => {
    const { currentTableEntities, sortDirection, sortColumn } = this.state;
    const newDirection = sortColumn === columnIndex && sortDirection === 'asc' ? 'desc' : 'asc';
    
    const sortedEntities = [...currentTableEntities].sort((a, b) => {
      let aValue = a.tableCells[columnIndex] || '';
      let bValue = b.tableCells[columnIndex] || '';
  
      // If sorting column 6 (CAD) or 7 (USD), remove commas and parse the value as a float for comparison
      if (columnIndex === 6 || columnIndex === 7) {
        aValue = parseFloat(aValue.replace(/,/g, '')) || 0; // Remove commas and parse to float
        bValue = parseFloat(bValue.replace(/,/g, '')) || 0;
      }
  
      if (newDirection === 'asc') {
        return aValue > bValue ? 1 : -1;
      } else {
        return aValue < bValue ? 1 : -1;
      }
    });
  
    this.setState({
      currentTableEntities: sortedEntities,
      sortColumn: columnIndex,
      sortDirection: newDirection,
    });
  };
  

  // Handle sorting logic when column header is clicked
  handleSort = (columnIndex) => {
    this.sortTableEntities(columnIndex);
  };


  closeAllNotes() {
    [...document.querySelectorAll('.table_item-row.is-active')].forEach((item) => {
      item.removeAttribute('style');
      item.classList.remove('is-active');
      item.querySelector('.table_view-note-btn span').innerHTML = 'View Note';
      for (let i = 0; i < item.children.length; i++) {
        item.children[i].removeAttribute('style');
        item.children[i].classList.remove('is-active');
      }
    });
  }

  setCurrentTab(currentTab) {
    this.closeAllNotes();
    this.setState({
      currentTab
    }, () => {
      // Fetch the horse details for the selected tab
      this.props.fetchHorseDetail(currentTab);
  
      // Update the currentTableEntities after fetching the details
      const currentTableEntities = 
        (this.props.pageDetailData && 
         this.props.pageDetailData.details && 
         this.props.pageDetailData.details.sireDescendants && 
         this.props.pageDetailData.details.sireDescendants.tableEntities) || [];
  
      this.setState({ currentTableEntities });
    });
  }
  

  tableHeadingMap(item, idx, arr) {
    if (this.isMobile && idx > 0) {
      return null;
    }
  
    // Skip rendering the second column (index 1)
    if (idx === 1) {
      return null;
    }
  
    const { sortColumn, sortDirection } = this.state;
    const isSorted = sortColumn === idx;
  
    return (
      <React.Fragment key={idx}>
        <div className="col">
          {item}
          {(idx === 0 || idx === 3 || idx === 6 || idx === 7) && (
            <div 
              onClick={() => this.handleSort(idx)} 
              className={`table_sort-alphabetical-btn ${isSorted ? (sortDirection === 'asc' ? 'is-asc' : 'is-desc') : ''}`}
            >
              <img src={iconSortArrow} alt="Sort" />
            </div>
          )}
        </div>
        {idx === arr.length - 1 && <div className="col"></div>}
      </React.Fragment>
    );
  }
  

  loadNewHorse(id) {
    this.props.history.push(`/horses/detail?id=${id}`);
  }

  sireTableEntitiesMap(item, idx) {
    const isBold = item.tableCells[1] === 'Yes'; // Check if the second column is "Yes"
  
    return (
      <div 
        onClick={() => { this.loadNewHorse(item.detailID); }} 
        key={idx} 
        className="table_item-row"
        style={{ fontWeight: isBold ? 'bold' : 'normal' }} // Apply bold if isBold is true
      >
        <div className="row pad-x-15px">
          {item.tableCells.map((entityItem, colIdx, arr) => (
            colIdx !== 1 && ( // Skip rendering the second column (index 1)
              <React.Fragment key={colIdx}>
                <div className="col d-flex justify-content-between d-md-block">
                  {this.state.isMobile && (
                    <div>
                      {this.props.pageDetailData.details.sireDescendants.tableHeadings[colIdx]}
                    </div>
                  )}
                  <div>
                    {(colIdx === 6 || colIdx === 7) && <span className="dollar-sign-margin">$</span>}
                    {entityItem}
                  </div>
                </div>
                {colIdx === arr.length - 1 && (
                  <div onClick={handleViewNote} className="col table_view-note-btn">
                    <span>View Note</span>
                    <img src={iconDownArrow} alt="View Note" />
                  </div>
                )}
              </React.Fragment>
            )
          ))}
        </div>
        <hr className="first-hr" />
        <div className="table_item-note">
          <div className="d-flex">
            <div className="spacer-15px" />
            <div>
              <div className="table_note-label">Notes</div>
              <div className="table_note-body" dangerouslySetInnerHTML={{ __html: item.note }} />
            </div>
          </div>
          <hr />
        </div>
      </div>
    );
  }  

  render() {
    return (
      <section>
        <DropDown 
          stallions={this.props.tableData.tableEntities}
          setCurrentTab={this.setCurrentTab.bind(this)}
          isMobile={this.props.isMobile}
          history={this.props.history}
        />
        <div className="table">
          <div className="container-fluid">
            <div className="table">
              <div className="row table_heading-row">
                {this.props.tableData.tableHeadings && this.props.tableData.tableHeadings.map(this.tableHeadingMap.bind(this))}
              </div>
              {this.state.currentTab && this.props.pageDetailData && this.props.pageDetailData.details.id && (
                <div>
                  {this.state.currentTableEntities.map(this.sireTableEntitiesMap.bind(this))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => ({
  pageDetailData: state.pageDetailData,
});

const mapDispatchToProps = {
  fetchHorseDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(StallionsTable);
